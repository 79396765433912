import moment from 'moment';
import { FC } from 'react';
import { ETradeLogsType, ITradeLog } from 'redux/reducers/logs/types';
import { roundingNumber } from 'services/utils/roundingNumber';

interface ITradeLogsTableRow {
	item: ITradeLog;
	type: ETradeLogsType;
}

const TradeLogsTableRow: FC<ITradeLogsTableRow> = ({ item, type }) => {
	return (
		<div className="tr">
			{type === ETradeLogsType.INTERNAL && (
				<div className="td">
					<p className="td-hidden-name">Timestamp</p>
					<p className="td-name--flex">{moment(item.created_at).format('YYYY-MM-DD')}</p>
					<span className="td-more-info">{moment(item.created_at).utc().format('HH:mm:ss')}</span>
				</div>
			)}
			{type === ETradeLogsType.EXTERNAL && (
				<div className="td">
					<p className="td-hidden-name">ID</p>
					<p className="td-name--flex">{item.id}</p>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p className="td-name--flex">{item.user_id}</p>
			</div>
			{type === ETradeLogsType.EXTERNAL && (
				<div className="td">
					<p className="td-hidden-name">Tx ID</p>
					<p className="td-name--flex">{item.transaction_id}</p>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Sell / Buy</p>
				<p className="td-name--flex">{item.from_asset_id}</p>
				<span className="td-more-info">{item.to_asset_id}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Sell amount / Buy amount</p>
				<p className="td-name--flex">{roundingNumber(item.sell_quantity, item.from_asset.code)}</p>
				<span className="td-more-info">
					{roundingNumber(item.buy_quantity, item.to_asset.code)}
				</span>
			</div>
			{type === ETradeLogsType.INTERNAL && (
				<div className="td td--right">
					<p className="td-hidden-name">Side</p>
					<p className="td-name--flex">{item.last_input}</p>
				</div>
			)}

			<div className="td td--right">
				<p className="td-hidden-name">Quantity</p>
				<p className="td-name--flex">{roundingNumber(item.quantity, item.to_asset.code)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Crypto quantity</p>
				<p className="td-name--flex">{roundingNumber(item.crypto_quantity, 'crypto')}</p>
			</div>
			{type === ETradeLogsType.INTERNAL && (
				<>
					<div className="td td--right">
						<p className="td-hidden-name">User Balance</p>
						<p className="td-name--flex">{item.user_balance}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Trade Fee</p>
						<p className="td-name--flex">{item.trade_fee}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Spread Fee</p>
						<p className="td-name--flex">{item.spread_fee}</p>
					</div>
				</>
			)}

			<div className="td td--right">
				<p className="td-hidden-name">Rate / Rate with spread</p>
				<p className="td-name--flex">{item.exchange_rate}</p>
				<span className="td-more-info">{item.exchange_rate_with_spread}</span>
			</div>
			{type === ETradeLogsType.EXTERNAL && (
				<>
					<div className="td">
						<p className="td-hidden-name">Side</p>
						<p className="td-name--flex">{item.last_input}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Request</p>
						<span className="td-more-info">{item.request_order}</span>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Response</p>
						<span className="td-more-info">{item.response || '-'}</span>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Created at</p>
						<p className="td-name--flex">{moment(item.created_at).format('YYYY-MM-DD')}</p>
						<span className="td-more-info">{moment(item.created_at).utc().format('HH:mm:ss')}</span>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Updated at</p>
						<p className="td-name--flex">{moment(item.updated_at).format('YYYY-MM-DD')}</p>
						<span className="td-more-info">{moment(item.updated_at).utc().format('HH:mm:ss')}</span>
					</div>
				</>
			)}
		</div>
	);
};

export default TradeLogsTableRow;
